<template src="./logout.html"></template>

<script>
import GlobalLogout from '~/src/components/global/logout/logout';
export default {
  name: 'GondolaMiniLogout',
  components: {
    GlobalLogout,
  },
  data() {
    return {
      message: 'Terima kasih sudah menggunakan<br />SOCO Mask Station Recommendation Machine',
    };
  },
  created() {
    setTimeout(() => {
      this.$store.dispatch('destroyQrSession', {
        router: this.$router,
        redirectUrl: '/gondola-mini/home',
      });
    }, 2000);
  },
};
</script>
