<template>
  <div class="logout-state">
    <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>

    <h2 class="logout-state__heading">LOGGING OUT</h2>
    <p class="logout-state__copywriting" v-html="message"></p>
  </div>
</template>

<script>
export default {
  name: 'GlobalLogout',
  props: ['message'],
};
</script>
